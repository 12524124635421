/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, ContactForm } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader />

        <Column className="pb--20 pt--80" name={"wstęp"}>
          
          <ColumnWrap className="column__flex el--1 pb--10 pt--60" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Kontakt"}>
              </Title>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

              <Subtitle className="subtitle-box" content={"+48 797 811 2X0"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--08" content={"info@twoje-stronyy.com"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--30" name={"kontakt"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 el--1 pb--20 pt--20" anim={"2"} animS={"4"} columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style2 fs--18" style={{"maxWidth":1115}} action={"/contact"} fields={[{"name":"Pełne imię","type":"text","required":true,"placeholder":"Wpisz pełne imię"},{"name":"Kontakt e-mail","type":"email","required":true,"placeholder":"Wpisz swój e-mail"},{"name":"Numer telefonu","type":"text","placeholder":"Telefon +480"},{"name":"Wiadomość","type":"textarea","required":true,"placeholder":"Wpisz tekst wiadomości"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-szp6dj --parallax pb--80 pt--80" name={"stopka"} layout={"l1"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --left el--3 pb--20 pt--20 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: white;\">Domaniewski</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\">Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje.</span>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\">[[UNICreatedě]]</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: white;\">Kontakt</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":269}} content={"<span style=\"color: white;\">Pańska 18, Warszawa<br>+48 797 811 2X0<br>info@twoje-stronyy.com</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--right fs--24" content={"<span style=\"color: white;\">Usługi / Cennik</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--right fs--24" content={"<span style=\"color: white;\">Galeria</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--right fs--24" content={"<span style=\"color: white;\">Kontakt</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--right fs--24" content={"<span style=\"color: white;\">Powrót do góry</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}